
.loading-view {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.loading-view > p {
  font-size: 12px;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
}
