
.header {
  width: 100%;
  height: 91px;
  padding: 0 30px;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0cc;
}

.header h2 {
  color: #fff;
  font-size: 20px;
}

.header button {
  border: 0;
  padding: 10px 20px;
  font-size: 14px;
  letter-spacing: 3px;
  font-weight: 700;
  text-transform: uppercase;
  color: #0cc;
  background-color: #e4fd48;
  font-family: 'Lato', sans-serif;
  cursor: pointer;
  transition: background-color .3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.header button:hover {
  background-color: #d0f102;
}