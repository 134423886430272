
* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  min-height: 100vh;
  background-color: #eee;
}

#root {
  min-height: 100vh;
}